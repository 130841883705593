import { useState } from 'react';
import { Transition } from '@headlessui/react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../assets/imgs/logo.svg';
import SocialMedia from './shared/SocialMedia';
import ClickAwayListener from 'react-click-away-listener';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const classNameDesktop = 'text-dark-900 hover:bg-blue-900 hover:text-white-50 px-3 py-2 rounded-md';
  const classNameMobile = 'hover:bg-white-100 text-dark-900 block px-3 py-2 rounded-md text-base font-medium';

  const clickItem = () => window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });

  return (
    <nav className="bg-white-50 fixed top-0 z-30 w-full shadow px-2 py-2 sm:px-4">
      <div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <Link to="/" onClick={clickItem}>
                <img className="h-14 w-1h-14 hover:scale-105 transition" src={logo} alt="FactorK" />
              </Link>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  <NavLink className={({ isActive }) => (isActive ? `${classNameDesktop} bg-white-200 font-extrabold` : classNameDesktop)} to="/" onClick={clickItem}>
                    Home
                  </NavLink>
                  <NavLink className={({ isActive }) => (isActive ? `${classNameDesktop} bg-white-200 font-extrabold` : classNameDesktop)} to="about-us" onClick={clickItem}>
                    About us
                  </NavLink>
                  <NavLink className={({ isActive }) => (isActive ? `${classNameDesktop} bg-white-200 font-extrabold` : classNameDesktop)} to="services" onClick={clickItem}>
                    Services
                  </NavLink>
                  <NavLink className={({ isActive }) => (isActive ? `${classNameDesktop} bg-white-200 font-extrabold` : classNameDesktop)} to="team" onClick={clickItem}>
                    Team
                  </NavLink>
                  <NavLink className={({ isActive }) => (isActive ? `${classNameDesktop} bg-white-200 font-extrabold` : classNameDesktop)} to="careers" onClick={clickItem}>
                    Careers
                  </NavLink>
                  <NavLink className={({ isActive }) => (isActive ? `${classNameDesktop} bg-white-200 font-extrabold` : classNameDesktop)} to="blog" onClick={clickItem}>
                    Blog
                  </NavLink>
                  <NavLink className={classNameDesktop} to="/#contact-us">
                    Contact Us
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="hidden md:block">
              <SocialMedia />
            </div>

            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-white-100 inline-flex items-center justify-center p-2 rounded-md text-blue-900 hover:text-white-50 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white-50"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                ) : (
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="md:hidden" id="mobile-menu">
            <ClickAwayListener
              onClickAway={(e) => {
                if (e.type === 'click') {
                  setIsOpen(false);
                }
              }}
            >
              <>
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  <NavLink className={({ isActive }) => (isActive ? `${classNameMobile} bg-white-200 font-bold` : classNameMobile)} to="/">
                    Home
                  </NavLink>
                  <NavLink className={({ isActive }) => (isActive ? `${classNameMobile} bg-white-200 font-bold` : classNameMobile)} to="about-us">
                    About us
                  </NavLink>
                  <NavLink className={({ isActive }) => (isActive ? `${classNameMobile} bg-white-200 font-bold` : classNameMobile)} to="/services">
                    Services
                  </NavLink>
                  <NavLink className={({ isActive }) => (isActive ? `${classNameMobile} bg-white-200 font-bold` : classNameMobile)} to="/team">
                    Team
                  </NavLink>
                  <NavLink className={({ isActive }) => (isActive ? `${classNameMobile} bg-white-200 font-bold` : classNameMobile)} to="/careers">
                    Careers
                  </NavLink>
                  <NavLink className={({ isActive }) => (isActive ? `${classNameMobile} bg-white-200 font-bold` : classNameMobile)} to="/blog" >
                    Blog
                  </NavLink>
                  <NavLink className={classNameMobile} to="/#contact-us">
                    Contact Us
                  </NavLink>
                </div>
                <SocialMedia />
              </>
            </ClickAwayListener>
          </div>
        </Transition>
      </div>
    </nav>
  );
};

export default Header;
